import * as React from 'react'
import "./layout.scss";
import {Helmet} from "react-helmet";
import {MDBBadge, MDBBtn, MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarItem, MDBRow} from "mdb-react-ui-kit";
import getGlobalContent from "../lib/getAllContent";
import {StaticImage} from "gatsby-plugin-image";

interface LayoutProps {
  pageTitle?: string,
  description?: string,
  children: any
}

const Layout = ({ pageTitle, children, description } : LayoutProps) => {
    const globalData = getGlobalContent().file.childMdx.frontmatter;
    const websiteTitle = globalData.siteTitle;

    return (
        <div className="app">
          <Helmet title={pageTitle ? pageTitle + " | " + websiteTitle : websiteTitle}>
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="description" content={description} />
          </Helmet>
          <header className="fixed-top">
            <MDBNavbar light bgColor="white">
              <MDBContainer fluid>
                <MDBNavbarBrand>
                  <StaticImage src="../../content/drive-migrations-logo.png" alt="drivemigrations.com logo" height={40} placeholder="none" />
                  <strong>Data migration services <span>for Google Drive</span></strong>
                </MDBNavbarBrand>
                <MDBNavbarNav className="ms-auto w-auto">
                  <MDBNavbarItem>
                    <MDBBtn color="primary" href="/#contact">
                      <span>{globalData.contactButton}</span>
                      <MDBIcon icon="comment-alt" far />
                    </MDBBtn>
                  </MDBNavbarItem>
                </MDBNavbarNav>
              </MDBContainer>
            </MDBNavbar>
          </header>
          <main>
              {children}
          </main>
          <footer>
            <MDBFooter className="font-small text-white" bgColor="primary">
              <MDBContainer className="py-5">
                <MDBRow>
                  <MDBCol size="12" md="5" className="pb-5 pb-md-0">
                    <h2>{globalData.footerHeading}</h2>
                    <p className="mb-0 pr-md-5">
                      {globalData.footerParagraph}
                    </p>
                  </MDBCol>
                  <MDBCol size="12" md="3"> </MDBCol>
                  <MDBCol size="12" md="4">
                    <h2>{globalData.footerContactHeading}</h2>
                    <div className="contact-pill">
                        <a href={"tel:" + globalData.phoneNumber.replace(/ /g, '')}>
                          <MDBBadge pill color="white" className="text-black">
                            <MDBIcon icon="phone" size="2x" className="text-primary"/>
                            <span>{globalData.phoneNumber.replace(/ /g, "\u00a0")}</span>
                          </MDBBadge>
                        </a>
                    </div>
                    <div className="contact-pill">
                      <a href={"mailto:" + globalData.email}>
                        <MDBBadge pill color="white" className="text-black">
                          <MDBIcon icon="at" size="2x" className="text-primary"/>
                          <span>{globalData.email}</span>
                        </MDBBadge>
                      </a>
                    </div>
                    <div className="contact-pill">
                      <MDBBadge pill color="white" className="text-black">
                        <MDBIcon icon="map-marker-alt" size="2x" className="text-primary"/>
                        <span>{globalData.location}</span>
                      </MDBBadge>
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBContainer>
              <MDBContainer fluid className="footer-copyright text-center py-3 text-white-50">
                &copy; {new Date().getFullYear()} <a href={"https://" + globalData.domainName} className="text-white"> {globalData.domainName} </a>
              </MDBContainer>
            </MDBFooter>
          </footer>
        </div>
    )
}

export default Layout